/**
 * @flow
 * @relayHash cd68e3b2ca1722beef61d7250408d27b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ReferrerPage_referralCode$ref = any;
type ReferrerPage_referrals$ref = any;
export type routeConfig_Referrer_QueryVariables = {|
  code?: ?string
|};
export type routeConfig_Referrer_QueryResponse = {|
  +referralCode: ?{|
    +$fragmentRefs: ReferrerPage_referralCode$ref
  |},
  +referrals: ?{|
    +$fragmentRefs: ReferrerPage_referrals$ref
  |},
|};
export type routeConfig_Referrer_Query = {|
  variables: routeConfig_Referrer_QueryVariables,
  response: routeConfig_Referrer_QueryResponse,
|};
*/


/*
query routeConfig_Referrer_Query(
  $code: String
) {
  referralCode(code: $code, withCount: true) {
    ...ReferrerPage_referralCode
    id
  }
  referrals: referralCode(code: $code) {
    ...ReferrerPage_referrals
    id
  }
}

fragment ProgramHeader_program on Program {
  id
  itemId
  name
  image {
    url
    width
    height
  }
  options {
    key
    value
  }
}

fragment ReferrerPage_referralCode on ReferralCode {
  code
  createdAt
  ...RewardsList_referralCode
  ...ReferrerRewardStatusChart_referralCode
  referrer {
    code
    subscribers {
      email
      username
      id
    }
    id
  }
  subscribers {
    email
    emailHash
    username
    uid
    id
  }
  program {
    itemId
    accountType
    options {
      key
      value
    }
    ...ProgramHeader_program
    ...RewardsList_program
    id
  }
}

fragment ReferrerPage_referrals on ReferralCode {
  ...ReferrerReferralsTable_userSource_4qp04X
}

fragment ReferrerReferralsTable_userSource_4qp04X on ReferralCode {
  code
  users: referrals(first: 10, orderBy: CREATED_AT_DESC, withCount: true) {
    totalCount
    edges {
      cursor
      node {
        id
        code
        createdAt
        referralCount
        referralRank
        referrer {
          code
          subscribers {
            id
            email
            username
          }
          id
        }
        subscribers {
          id
          email
          username
        }
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}

fragment ReferrerRewardStatusChart_referralCode on ReferralCode {
  referralCount
  eligibleRewards {
    id
    referralsNeeded
    image {
      url
      width
      height
    }
  }
}

fragment RewardsList_program on Program {
  id
  rewards {
    id
    title
    description
    referralsNeeded
    archivedAt
    image {
      url
      width
      height
    }
  }
}

fragment RewardsList_referralCode on ReferralCode {
  eligibleRewards {
    id
    title
    description
    referralsNeeded
    archivedAt
    image {
      url
      width
      height
    }
  }
  earnedRewards {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = {
  "kind": "Variable",
  "name": "code",
  "variableName": "code"
},
v2 = {
  "kind": "Literal",
  "name": "withCount",
  "value": true
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referralsNeeded",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "archivedAt",
    "storageKey": null
  },
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referralCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  },
  (v2/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscriber",
  "kind": "LinkedField",
  "name": "subscribers",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_Referrer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ReferralCode",
        "kind": "LinkedField",
        "name": "referralCode",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferrerPage_referralCode"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "referrals",
        "args": (v4/*: any*/),
        "concreteType": "ReferralCode",
        "kind": "LinkedField",
        "name": "referralCode",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferrerPage_referrals"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeConfig_Referrer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ReferralCode",
        "kind": "LinkedField",
        "name": "referralCode",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Reward",
            "kind": "LinkedField",
            "name": "eligibleRewards",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Reward",
            "kind": "LinkedField",
            "name": "earnedRewards",
            "plural": true,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ReferralCode",
            "kind": "LinkedField",
            "name": "referrer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscriber",
                "kind": "LinkedField",
                "name": "subscribers",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscriber",
            "kind": "LinkedField",
            "name": "subscribers",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emailHash",
                "storageKey": null
              },
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uid",
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "itemId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Option",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Reward",
                "kind": "LinkedField",
                "name": "rewards",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "referrals",
        "args": (v4/*: any*/),
        "concreteType": "ReferralCode",
        "kind": "LinkedField",
        "name": "referralCode",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": "users",
            "args": (v13/*: any*/),
            "concreteType": "ReferralCodeConnection",
            "kind": "LinkedField",
            "name": "referrals",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReferralCodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReferralCode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "referralRank",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReferralCode",
                        "kind": "LinkedField",
                        "name": "referrer",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v14/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "referrals(first:10,orderBy:\"CREATED_AT_DESC\",withCount:true)"
          },
          {
            "alias": "users",
            "args": (v13/*: any*/),
            "filters": [
              "orderBy",
              "referrals",
              "referrers",
              "withCount",
              "withRank"
            ],
            "handle": "connection",
            "key": "ReferrerReferralsTable_users",
            "kind": "LinkedHandle",
            "name": "referrals"
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "cd68e3b2ca1722beef61d7250408d27b",
    "metadata": {},
    "name": "routeConfig_Referrer_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '619aa11fdb85ccf3c54a9999c56962b0';

module.exports = node;
